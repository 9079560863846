import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ItemCard from './itemCard'
import { getItems, writeItem } from '../clients/apiClient'
import cartItem from '../interfaces/cartItem'
import '../App.css';

interface Props {
    cartItems: cartItem[],
    setCartItems: ((cartItems: cartItem[]) => void),
    navigate: ((path: string) => void)
  }

function Gallery(props: Props) {
    const { cartItems, setCartItems, navigate } = props;
    
    const [items, setItems] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState(false);

    const getApiItems = async () => {
        // const dummyItem = {
        //     id: "1",
        //     images: ["19784", "63252", "536"],
        //     price: 49.99, 
        //     quantity: 1,
        //     name: "First with a much longer name now",
        //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        // }
        // await writeItem(dummyItem);
        let apiItems = await getItems()

        apiItems = apiItems.sort(function(a, b) { 
            if (a.quantity <= 0 && b.quantity > 0) {
                return 1
            }
            return -1
        })

        setItems(apiItems)
        setItemsLoaded(true)
    }

    useEffect(() => {
        // window.scrollTo(0, 0);
        getApiItems();
        return () => {setItems([])}
    }, []);

  return (
      <>
        <Typography gutterBottom variant="h4" component="div">
            Gallery
        </Typography>
            {!itemsLoaded
                ? <div className="loading-spinner">
                    <CircularProgress/> 
                  </div>
                : <>
                    {!items || !items.length
                        ?   <Typography gutterBottom variant="h5" component="div">
                                Coming Soon! Please check out our <Link to="/about">About Us</Link> page to learn more.
                            </Typography>
                        :   <>
                                <Grid 
                                    container 
                                    alignItems="center" 
                                    justifyContent="center" 
                                    spacing={1}
                                    rowSpacing={2}   
                                >
                                    {items.map((item) => {
                                        const { id } = item
                                        return (
                                            <Grid key={id} item lg={4}>
                                                <ItemCard 
                                                    item={item} 
                                                    cartItems={cartItems} 
                                                    setCartItems={setCartItems}
                                                    navigate={navigate}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </>
                    }
                </>
            }
        </>
  );
}

export default Gallery;
