import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import '../App.css';

function Footer() {
  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }}>
          Prickly Rose Clay Company LLC, 2022
        </Typography>
      </Toolbar>
    </AppBar> 
  );
}

export default Footer;
