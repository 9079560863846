import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { getItem } from '../clients/apiClient'
import cartItem from '../interfaces/cartItem'
import item from '../interfaces/item'
import '../App.css';

interface Props {
  cartItem: cartItem,
  cartItems: cartItem[],
  setCartItems: ((cartItems: cartItem[]) => void),
  navigate: ((path: string) => void)
}

function CartListItem(props: Props) {
  const { cartItem, cartItems, setCartItems, navigate } = props;
  const { id, price, quantity } = cartItem;

  const [apiItem, setApiItem] = useState(undefined);

  useEffect(() => {
    if (id) {
      getApiItem();
    }
  }, [id]);

  const getApiItem = async () => {
    const result: item = await getItem(id)

    if (result.quantity <= 0) {
      handleDeleteClick();
    }

    if (cartItem.quantity > result.quantity) {
      changeQuantity(result.quantity);
    }

    setApiItem(result);
  }

  const handleDetailsClick = () => {
    navigate(`../item/${id}`);
  }

  const handleDeleteClick = () => {
    const newCartItems: cartItem[] = cartItems.filter((mapCartItem) => mapCartItem.id !== id)
    setCartItems([...newCartItems])
  }

  const handleQuantityChange = (event: any) => {
    changeQuantity(event.target.value)
  }

  const changeQuantity = (value: number) => {
    const newCartItems = cartItems.map((mapCartItem) => {
      if (mapCartItem.id === id) {
        mapCartItem.quantity = value
      }
      return mapCartItem;
    })
    setCartItems([...newCartItems])
  }

  const getQuantitySelectItems = () => {
    let quantitySelectItems: any[] = []
    for (let i = 1; i <= apiItem.quantity; i++) {
      quantitySelectItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    return quantitySelectItems;
  }

  return (
    <div className="cart-item">
      {!apiItem 
        ? <CircularProgress /> 
        : <Grid container spacing={2}>
        <Grid className="item-details-text" item sm={6}>
          <Typography gutterBottom variant="h5" component="div">
            {apiItem.name}
          </Typography> 
          <Typography gutterBottom variant="body1" component="div">
            {apiItem.description}
          </Typography>
          <Button size="medium" color="secondary" variant="outlined" onClick={handleDetailsClick}>
            Details
          </Button>
        </Grid>
        <Grid className="cart-list-item-column" item sm={2}>
          <Typography gutterBottom variant="h6" component="div">
            ${price}
          </Typography>       
        </Grid>
        <Grid item sm={2} className="right-align cart-list-item-column">
          {apiItem &&
            <FormControl fullWidth className="cart-qty-section">
              <InputLabel id="qty-select-label">QTY</InputLabel>
              <Select
                labelId="qty-select-label"
                id="qty-simple-select"
                value={quantity}
                label="QTY"
                onChange={handleQuantityChange}
              >
                {getQuantitySelectItems()}
              </Select>
            </FormControl>          
          }

        </Grid>
        <Grid item sm={2} className="right-align cart-list-item-column">
          <IconButton onClick={handleDeleteClick} color="warning" aria-label="remove" component="span">
            <DeleteForeverIcon />
          </IconButton>
        </Grid>
      </Grid>
      }
    </div>  
  );
}

export default CartListItem;
