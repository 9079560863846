/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      description
      price
      quantity
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        price
        quantity
        images
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      status
      firstName
      lastName
      emailAddress
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      subTotal
      salesTax
      shipping
      total
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        firstName
        lastName
        emailAddress
        phoneNumber
        addressLine1
        addressLine2
        city
        state
        zip
        subTotal
        salesTax
        shipping
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderItems = /* GraphQL */ `
  query GetOrderItems($id: ID!) {
    getOrderItems(id: $id) {
      id
      itemId
      orderId
      price
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemId
        orderId
        price
        quantity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      emailAddress
      allowCommunications
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailAddress
        allowCommunications
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        city
        state
        zip
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByDate = /* GraphQL */ `
  query ItemsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        price
        quantity
        images
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
