import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CartListItem from './cartListItem'
import cartItem from '../interfaces/cartItem'
import {calculateSubTotal} from '../handler/cartHandler'
import '../App.css';

interface Props {
  cartItems: cartItem[],
  setCartItems: ((cartItems: cartItem[]) => void),
  navigate: ((path: string) => void)
}

const Cart = (props: Props) => {
  const { cartItems, setCartItems, navigate } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const subTotal = calculateSubTotal(cartItems);

  const handleCheckOutClick = () => {
    navigate(`../checkout`);
  }

  const handleContinueShoppingClick = () => {
    navigate(`../`);
  }

  return (
    <Paper elevation={3} className="cart-paper">
      <Typography gutterBottom variant="h4" component="div">
        Cart
      </Typography>
      {cartItems && cartItems.map((cartItem) => {
          return (
            <CartListItem 
                key={cartItem.id}
                cartItem={cartItem} 
                cartItems={cartItems} 
                setCartItems={setCartItems}
                navigate={navigate}
            />
          )
      })}
      {!cartItems || !cartItems.length ? 
        <>
          <Typography gutterBottom variant="body1" component="div">
            There are no items in your cart.
          </Typography>
          <div>
            <Button size="medium" color="info" variant="outlined" onClick={handleContinueShoppingClick}>
              Continue Shopping
            </Button>
          </div>
        </>
        :
        <>
          <Typography gutterBottom variant="h6" component="div">
          Subtotal: ${subTotal.toFixed(2)}
          </Typography>
          <div className="button-div">
            <Button size="medium" color="info" variant="outlined" onClick={handleCheckOutClick}>
              Checkout
            </Button>
          </div>
          <div className="button-div">
            <Button size="medium" color="secondary" variant="outlined" onClick={handleContinueShoppingClick}>
              Continue Shopping
            </Button>
          </div>
        </>
      }
    </Paper>
  );
}

export default Cart;