import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import cartItem from '../interfaces/cartItem'
import '../App.css';

interface Props {
  cartItems: cartItem[],
  navigate: ((path: string) => void)
}

const Header = (props: Props) => {
  const { cartItems, navigate } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleCartClick = () => {
    navigate(`../cart`);
  }

  const handleHomeClick = () => {
    navigate(`../`);
  }

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  }
  
  const handleMenuGalleryClick = () => {
    handleMenuClose();
    navigate(`../`);
  }

  const handleMenuAboutClick = () => {
    handleMenuClose();
    navigate(`../about`);
  }

  const handleMenuContactClick = () => {
    handleMenuClose();
    navigate(`../contact`);
  }
  
  const handleMenuCartClick = () => {
    handleMenuClose();
    navigate(`../cart`);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
      <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleMenuGalleryClick}>Gallery</MenuItem>
            <MenuItem onClick={handleMenuCartClick}>Cart</MenuItem>
            <Divider />
            <MenuItem onClick={handleMenuAboutClick}>About Us</MenuItem>
            <MenuItem onClick={handleMenuContactClick}>Contact Us</MenuItem>
          </Menu>
          <Typography variant="h6" component="span" sx={{ flexGrow: 1 }} onClick={handleHomeClick}>
            <span className="header-title">Prickly Rose</span>
          </Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleCartClick}
          >
            <ShoppingCartIcon /> ({cartItems.length})
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>    
  );
}

export default Header;
