import { API } from 'aws-amplify';
import item from '../interfaces/item'
import { itemsByDate, getItem as getItemQuery, createItem } from '../graphql';

// const items: item[] = [
//     {
//         id: "1",
//         images: ["19784", "63252", "536"],
//         price: 49.99, 
//         quantity: 1,
//         name: "First with a much longer name now",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
//     },
//     {
//         id: "2",
//         images: ["63252", "19784", "536"],
//         price: 99.99, 
//         quantity: 5,
//         name: "Second",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
//     },
//     {
//         id: "3",
//         images: ["536", "19784", "63252"],
//         price: 49.99, 
//         quantity: 0,
//         name: "Third",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
//     },
//     {
//         id: "4",
//         images: ["19784", "63252", "536"],
//         price: 49.99, 
//         quantity: 1,
//         name: "First",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
//     },
//     {
//         id: "5",
//         images: ["63252", "19784", "536"],
//         price: 99.99, 
//         quantity: 5,
//         name: "Second",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
//     },
//     {
//         id: "6",
//         images: ["536", "19784", "63252"],
//         price: 49.99,
//         quantity: 0,
//         name: "Third",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
//     },
// ]

export const writeItem = async (item: item) => {
    await API.graphql({ query: createItem, variables: { input: item } });
}

export const getItems = async (): Promise<item[]> => {
    let apiData: any = await API.graphql({ query: itemsByDate, variables: { type: "Item", sortDirection: "ASC" } });

    let results = apiData.data.itemsByDate.items

    while (apiData.data.itemsByDate.nextToken) {
        apiData = await API.graphql({ query: itemsByDate, variables: { type: "Item", sortDirection: "ASC", nextToken: apiData.data.itemsByDate.nextToken } });
        results = results.concat(apiData.data.itemsByDate.items)
    }

    return results;
}

export const getItem = async (id: string): Promise<item> => {
    const apiData: any = await API.graphql({ query: getItemQuery, variables: {id} });
    return apiData.data.getItem;
}