import cartItem from '../interfaces/cartItem'

export const shippingFee: number = 5.00
export const taxRate: number = 7.7380

export const calculateSubTotal = (cartItems: cartItem[]) => {
    let subTotal: number = 0.00;

    cartItems.forEach(cartItem => {
      const itemTotal = cartItem.price * cartItem.quantity;
      subTotal += itemTotal; 
    });
  
    return subTotal;
}

export const calculateSalesTax = (subTotal: number) => {
    const salesTax = (subTotal/100) * taxRate;
    return salesTax;
}

export const calculateTotal = (subTotal: number, salesTax: number) => {
    const total = subTotal + salesTax + shippingFee;
    return total;
}