import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import '../App.css';

interface Props {
}

function Contact(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const imgUrl = "https://prickly-rose-clay-co-public.s3.amazonaws.com/Instagram_icon.png"

  return (
    <Paper elevation={3} className="cart-paper">
      <Typography gutterBottom variant="h4" component="div">
        Contact Us
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        We would love to hear any feedback, questions, or comments.
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
      To contact us, please send an email to <a href="mailto:PricklyRoseClayCo@Gmail.com">PricklyRoseClayCo@gmail.com</a>.
      </Typography>
      <a target="_blank" href="https://instagram.com/pricklyroseclayco?igshid=YmMyMTA2M2Y=">
        <img src={imgUrl} height="60px" width="60px" alt="instgram profile" />
      </a>
      <a href="mailto:PricklyRoseClayCo@Gmail.com">
        <MailOutlineIcon className="email-icon" />
      </a>
    </Paper>
 
  );
}

export default Contact;
