import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import cartItem from '../interfaces/cartItem'
import item from '../interfaces/item'

interface Props {
  item: item,
  cartItems: cartItem[],
  setCartItems: ((cartItems: cartItem[]) => void),
  navigate: ((path: string) => void)
}

function ItemCard(props: Props) {
  const { item, cartItems, setCartItems, navigate } = props;
  const { id, images, price, name, quantity } = item;

  const imageUrl = `https://prickly-rose-clay-co-public.s3.amazonaws.com/${images[0]}.jpg`

  const handleSelect = () => {
    navigate(`../item/${id}`);
  }

  const handleAddClick = () => {
    const newCartItem = { id: item.id, price: item.price, quantity: 1}
    setCartItems([...cartItems, newCartItem])
  }

  const handleRemoveClick = () => {
    const newCartItems = cartItems.filter((cartItem) => cartItem.id !== item.id)
    setCartItems([...newCartItems])
  }

  const isItemInCart = () => {
    return cartItems.find((cartItem) => cartItem.id === item.id)
  }

  const getActionButton = () => {
    if (quantity <= 0) {
      return (
        <Button disabled={true} size="medium" color="info" variant="outlined">
          <ShoppingCartTwoToneIcon />
        </Button>
      )
    } else if (isItemInCart()) {
      return (
        <Button size="medium" color="warning" variant="outlined" onClick={handleRemoveClick} >
          <RemoveShoppingCartIcon />
        </Button>
      )
    } else {
      return (
        <Button size="medium" color="info" variant="outlined" onClick={handleAddClick} >
          <ShoppingCartTwoToneIcon />
        </Button>
      )
    }
  }

  return (
    <Card className="item-card" sx={{ maxWidth: 345 }}>
      <CardActionArea onClick={handleSelect}>
        <CardMedia
          component="img"
          height="400"
          image={imageUrl}
          alt={name}
        />
        <CardContent className="item-card-content">
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {getActionButton()}
        {quantity <= 0 ?
          <Typography className="item-card-sold-out" gutterBottom variant="h6" component="div">
            Sold Out!
          </Typography> 
        :
          <Typography className="item-card-price" gutterBottom variant="h6" component="div">
            ${price.toFixed(2)}
          </Typography>
        }
        <Button className="right-align" size="medium" color="secondary" variant="outlined" onClick={handleSelect}>
         <InfoIcon />
        </Button>
      </CardActions>
    </Card>
  );
}

export default ItemCard;
