/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addOrder = /* GraphQL */ `
  mutation AddOrder(
    $id: String
    $status: String
    $firstName: String
    $lastName: String
    $emailAddress: String
    $allowCommunications: Boolean
    $phoneNumber: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $zip: String
    $subTotal: Float
    $salesTax: Float
    $shipping: Float
    $total: Float
    $paymentToken: String
    $orderItems: AWSJSON
  ) {
    addOrder(
      id: $id
      status: $status
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      allowCommunications: $allowCommunications
      phoneNumber: $phoneNumber
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      state: $state
      zip: $zip
      subTotal: $subTotal
      salesTax: $salesTax
      shipping: $shipping
      total: $total
      paymentToken: $paymentToken
      orderItems: $orderItems
    )
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      description
      price
      quantity
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      description
      price
      quantity
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      description
      price
      quantity
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      emailAddress
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      subTotal
      salesTax
      shipping
      total
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      emailAddress
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      subTotal
      salesTax
      shipping
      total
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      emailAddress
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      subTotal
      salesTax
      shipping
      total
      createdAt
      updatedAt
    }
  }
`;
export const createOrderItems = /* GraphQL */ `
  mutation CreateOrderItems(
    $input: CreateOrderItemsInput!
    $condition: ModelOrderItemsConditionInput
  ) {
    createOrderItems(input: $input, condition: $condition) {
      id
      itemId
      orderId
      price
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderItems = /* GraphQL */ `
  mutation UpdateOrderItems(
    $input: UpdateOrderItemsInput!
    $condition: ModelOrderItemsConditionInput
  ) {
    updateOrderItems(input: $input, condition: $condition) {
      id
      itemId
      orderId
      price
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderItems = /* GraphQL */ `
  mutation DeleteOrderItems(
    $input: DeleteOrderItemsInput!
    $condition: ModelOrderItemsConditionInput
  ) {
    deleteOrderItems(input: $input, condition: $condition) {
      id
      itemId
      orderId
      price
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      emailAddress
      allowCommunications
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      emailAddress
      allowCommunications
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      emailAddress
      allowCommunications
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      createdAt
      updatedAt
    }
  }
`;
