import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IosShareIcon from '@mui/icons-material/IosShare';
import Snackbar from '@mui/material/Snackbar';
import { getItem } from '../clients/apiClient'
import cartItem from '../interfaces/cartItem'
import '../App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface Props {
  cartItems: cartItem[],
  setCartItems: ((cartItems: cartItem[]) => void),
  navigate: ((path: string) => void)
}

function ItemDetails(props: Props) {
  const { cartItems, setCartItems, navigate } = props;

  const [item, setItem] = useState(undefined);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  let { id } = useParams();

  const getApiItem = async () => {
    const apiItem = await getItem(id)
    setItem(apiItem);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (id) {
      getApiItem();
    }
  }, [id]);

  const handleAddClick = () => {
    const newCartItem = { id: item.id, price: item.price, quantity: 1}
    setCartItems([...cartItems, newCartItem])
  }

  const handleRemoveClick = () => {
    const newCartItems = cartItems.filter((cartItem) => cartItem.id !== item.id)
    setCartItems([...newCartItems])
  }

  const handleContinueShoppingClick = () => {
    navigate(`../`);
  }

  const handleViewCartClick = () => {
    navigate(`../cart`);
  }

  const handleShareClick = () => {
    let url = ''
    if (process.env.NODE_ENV === 'development') {
      url = `https://dev.pricklyroseclay.com/item/${id}`
    } else {
      url = `https://pricklyroseclay.com/item/${id}`
    }

    const navigator: any = window.navigator
    navigator['clipboard'].writeText(url);

    setSnackBarOpen(true);
  }

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const isItemInCart = () => {
    return cartItems.find((cartItem) => cartItem.id === item.id)
  }

  const getActionButton = () => {
    if (item.quantity <= 0) {
      return (
        <Button disabled={true} size="medium" color="info" variant="outlined">
          SOLD OUT!
        </Button>
      )
    } else if (isItemInCart()) {
      return (
        <Button size="medium" color="warning" variant="outlined" onClick={handleRemoveClick} endIcon={<RemoveShoppingCartIcon />}>
          Remove from cart
        </Button>
      )
    } else {
      return (
        <Button size="medium" color="info" variant="outlined" onClick={handleAddClick} endIcon={<ShoppingCartTwoToneIcon />}>
          Add to cart
        </Button>
      )
    }
  }

  const imgUrl = "https://prickly-rose-clay-co-public.s3.amazonaws.com/"

  return (
    <>
    <Grid container spacing={2}>
      {!item 
        ? <div className="loading-spinner">
            <CircularProgress/> 
          </div>
        : <>
        <Grid className="item-details-text" item md={6}>
          <Typography gutterBottom variant="h4" component="div">
            {item.name}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            ${item.price}
          </Typography>
          <Typography gutterBottom variant="body1" component="div">
            {item.description}
          </Typography>
          <Typography gutterBottom variant="body1" component="div">
            Quantity Available: {item.quantity}
          </Typography>
          <div className="button-div">
            <Button size="medium" color="primary" onClick={handleShareClick}>
            <IosShareIcon />
            </Button>        
          </div>
          <div className="button-div">
          {getActionButton()}          
          </div>
          <div className="button-div">
            <Button size="medium" color="secondary" variant="outlined" onClick={handleViewCartClick}>
             View Cart
            </Button>
          </div>
          <div className="button-div">
            <Button size="medium" color="secondary" variant="outlined" onClick={handleContinueShoppingClick}>
             Continue Shopping
            </Button>
          </div>
        </Grid>
        <Grid item md={6}>
          <Carousel>
              {item.images && item.images.map((imageId: string) => {
                const imageUrl = `${imgUrl}${imageId}.jpg`
                return (
                  <div key={imageId}>
                    <img src={imageUrl} alt="" />
                  </div>
                )
              })}
          </Carousel>      
        </Grid>
        </>
    }
    </Grid>
    <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message="URL Copied!"
      />
    </>
  );
}

export default ItemDetails;
