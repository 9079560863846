import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from './components/header'
import Gallery from './components/gallery'
import ItemDetails from './components/itemDetails'
import Cart from './components/cart'
import Checkout from './components/checkout'
import Footer from './components/footer'
import About from './components/about'
import Contact from './components/contact'
import logo from './logo/Prickly_Rose_Logo.png'
import './App.css';

function App() {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    getSessionCartItems();
  }, []);

  useEffect(() => {
    setSessionCartItems();
  }, [cartItems]);

  let navigate = useNavigate();

  const theme = createTheme({
    typography: {
      fontFamily: ['serif'].join(','),
    },
    palette: {
      primary: {
        main: "#768d79",
      },
      secondary: {
        main: grey[700],
      },
      info: {
        main: grey[900]
      }
    },
  });

  const sessionKey = "cartItems"

  const getSessionCartItems = () => {
    const sessionValue = window.sessionStorage.getItem(sessionKey);
    if (sessionValue) {
      const asArray = sessionValue.split('|')
      const sessionCartItems = asArray.map((item) => {
        return JSON.parse(item)
      })
      setCartItems(sessionCartItems)
    }
  }

  const setSessionCartItems = () => {
    const cartItemsString = cartItems.reduce((result, item, index) => {
      if (index === 0) {
        return JSON.stringify(item)
      }
      return `${result}|${JSON.stringify(item)}`
    }, "")

    window.sessionStorage.setItem(sessionKey, cartItemsString);
  }

  return (
    <div className="App">
    <ThemeProvider theme={theme}>
      <Header cartItems={cartItems} navigate={navigate} />
      <Container className="main-container" maxWidth="lg">
        <Routes>
          <Route path="/" element={
            <Gallery 
              cartItems={cartItems} 
              setCartItems={setCartItems}
              navigate={navigate}
            />
          } />
          <Route path="/item/:id" element={
            <ItemDetails 
              cartItems={cartItems} 
              setCartItems={setCartItems}
              navigate={navigate}
            />
          } />
          <Route path="/cart" element={
            <Cart 
              cartItems={cartItems} 
              setCartItems={setCartItems}
              navigate={navigate}
            />
          } />
          <Route path="/checkout" element={
            <Checkout 
              cartItems={cartItems} 
              setCartItems={setCartItems}
              navigate={navigate}
            />
          } />
          <Route path="/about" element={<About/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
      </Container>
      <Box
        className="body-logo"
        component="img"
        sx={{
          height: 300,
          width: 300,
        }}
        alt="Prickly Rose Clay Company Logo"
        src={logo}
      />
      <Footer/>
    </ThemeProvider>
    </div>
  );
}

export default App;
