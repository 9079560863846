import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import '../App.css';

function About() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const imgUrl = "https://prickly-rose-clay-co-public.s3.amazonaws.com/Instagram_icon.png"

  return (
    <Paper elevation={3} className="about-paper">
      <Typography gutterBottom variant="h4" component="div">
        About Us
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        Hi friends!
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        We are Erin and Andy and we're the owners of Prickly Rose Clay Co.. 
        Thank you so much for visiting our website!
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        All pieces are handmade with love and care by Erin in her tiny studio space, 
        while Andy is likely working on the tech and business side of things.
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        If you have any feedback, questions, or comments, please <Link to="/contact">contact us</Link> and we'll get right back to you.
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        Thank you for supporting our small business!
      </Typography>
      <Typography className="about-div" gutterBottom variant="body1" component="div">
        - Prickly Rose Clay Co.
      </Typography>
      <a target="_blank" href="https://instagram.com/pricklyroseclayco?igshid=YmMyMTA2M2Y=">
        <img src={imgUrl} height="60px" width="60px" alt="instgram profile" />
      </a>
      <a href="mailto:PricklyRoseClayCo@Gmail.com">
        <MailOutlineIcon className="email-icon" />
      </a>
    </Paper>
  );
}

export default About;
