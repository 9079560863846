import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckoutForm from './checkoutForm'
import CheckoutPayment from './checkoutPayment'
import { calculateSubTotal, calculateSalesTax, calculateTotal, shippingFee, taxRate } from '../handler/cartHandler'
import { createOrder } from '../clients/functionClient'
import order from '../interfaces/order'
import cartItem from '../interfaces/cartItem'
import '../App.css';

interface Props {
  cartItems: cartItem[],
  setCartItems: ((cartItems: cartItem[]) => void),
  navigate: ((path: string) => void)
}

function Checkout(props: Props) {
  const { cartItems, setCartItems, navigate } = props;

  const subTotal = calculateSubTotal(cartItems);
  const salesTax = calculateSalesTax(subTotal);
  const total = calculateTotal(subTotal, salesTax);

  const defaultOrder: order = {
    id: undefined,
    status: undefined,
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    allowCommunications: true,
    subTotal,
    salesTax,
    shipping: shippingFee,
    total,
    orderItems: []
  }

  const [order, setOrder] = useState(defaultOrder);
  const [checkoutFormSubmitted, setCheckoutFormSubmitted] = useState(false);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState(undefined);

  useEffect(() => {
    if (cartItems.length === 0) {
      navigate(`../cart`);
    }
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [checkoutFormSubmitted])

  useEffect(() => {
    if (cartItems.length === 0) {
      navigate(`../cart`);
    }
  }, [cartItems])

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '280px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setOrder({ ...order,
      [name]: value
    });
  }

  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    setCheckoutFormSubmitted(true)
  }

  const handlePrevious = () => {
    setCheckoutFormSubmitted(false)
  }

  const handlePaymentToken = async (paymentToken: any) => {
    setProcessingOrder(true);
    setIsModalOpen(true);
    const newOrder = { ...order, subTotal, salesTax, shipping: shippingFee, total }

    try {
      const submitOrderResponse: any = await createOrder(newOrder, cartItems, paymentToken);

      if (submitOrderResponse.data.addOrder) {
        setOrderId(submitOrderResponse.data.addOrder)
      }
    } catch (error) {
      console.error("Error processing order", error);
    } finally {
      setProcessingOrder(false);
    }
  }

  const handleModalClose = (event: any) => {
    if (orderId) {
      setCartItems([])
    }
    setIsModalOpen(false);
  }

  const handleCancel = (event: any) => {
    event.preventDefault();
    navigate(`../cart`);
  }

  return (
    <>
      <Paper elevation={3} className="cart-paper">
        <Typography gutterBottom variant="h4" component="div">
          Checkout
        </Typography>
        {!checkoutFormSubmitted ?
          <CheckoutForm
            handleSubmitForm={handleSubmitForm}
            handleCancel={handleCancel}
            order={order}
            handleInputChange={handleInputChange}
          />        
        :
          <CheckoutPayment
            order={order}
            handlePaymentToken={handlePaymentToken}
            handlePrevious={handlePrevious}
            handleCancel={handleCancel}
          />        
        }
      </Paper>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {processingOrder
          ? <div className="loading-spinner">
              <CircularProgress/> 
            </div>
          : <>
            {orderId 
            ? <>
              <Typography id="modal-modal-title" variant="h6" component="div">
                Thank you for your order!
              </Typography>
              <Typography id="modal-modal-description" className="modal-div" variant="body1" component="div">
                We have sent an order confirmation to your email address.
              </Typography>
              <Typography className="modal-div" variant="body1" component="div">
                Your order id is {orderId}.
              </Typography>
              <Typography className="modal-div" variant="body1" component="div">
                Please allow up to 10 business days for your order to ship.
              </Typography>    
              <Typography className="modal-div" variant="body1" component="div">
                If you have any issues or questions, please <Link to="/contact">contact us</Link>!
              </Typography>    
              <Typography className="modal-div" variant="body1" component="div">
                - Prickly Rose Clay Company
              </Typography>                                     
              </>
            : <>
              <Typography id="modal-modal-title" variant="h6" component="div">
                There was an error processing your request.
              </Typography>
              <Typography id="modal-modal-description" className="modal-div" variant="body1" component="div">
                If you continue to experience issues, please <Link to="/contact">contact us</Link>!
              </Typography>
              <Typography className="modal-div" variant="body1" component="div">
                - Prickly Rose Clay Company
              </Typography>                 
              </>
            }
          </>
          }
        </Box>
      </Modal>
    </>
  );
}

export default Checkout;