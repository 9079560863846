import React from 'react';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import '../App.css';

interface Props {
  handleSubmitForm: any,
  handleCancel: any,
  order: any,
  handleInputChange: any
}

function CheckoutForm(props: Props) {
  const { handleSubmitForm, order, handleInputChange, handleCancel } = props;

  return (
    <form onSubmit={handleSubmitForm}>
      <Divider variant="middle" className="form-divider" >Contact Details</Divider>
      <div className="form-div">
        <TextField
          required
          className="input-component"
          id="first-name"
          label="First Name"
          name="firstName"
          value={order.firstName}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-div">
        <TextField
          required
          className="input-component"
          id="last-name"
          label="Last Name"
          name="lastName"
          value={order.lastName}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-div">
        <TextField
          required
          className="input-component"
          id="email-address"
          label="Email Address"
          name="emailAddress"
          value={order.emailAddress}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-div">
        <TextField
          className="input-component"
          id="phone-number"
          label="Phone Number"
          name="phoneNumber"
          value={order.phoneNumber}
          onChange={handleInputChange}
        />
      </div>
      <Divider variant="middle" className="form-divider" >Shipping Details</Divider>
      <div className="form-div">
        <TextField
          required
          className="input-component"
          id="address-1"
          label="Address Line 1"
          name="addressLine1"
          value={order.addressLine1}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-div">
      <TextField
        className="input-component"
        id="address-2"
        label="Address Line 2"
        name="addressLine2"
        value={order.addressLine2}
        onChange={handleInputChange}
      />
      </div>
      <div className="form-div">
      <TextField
        required
        className="input-component"
        id="city"
        label="City"
        name="city"
        value={order.city}
        onChange={handleInputChange}
      />
      </div>
      <div className="form-div">
      <TextField
        required
        className="input-component"
        id="state"
        label="State"
        name="state"
        value={order.state}
        onChange={handleInputChange}
      />
      </div>
      <div className="form-div">
      <TextField
        required
        className="input-component"
        id="zip"
        label="Zip"
        name="zip"
        value={order.zip}
        onChange={handleInputChange}
      />
      </div>
      <div className="checkbox-dev">
        <Checkbox
          id="allowCommunications"
          name="allowCommunications"
          checked={order.allowCommunications}
          onChange={handleInputChange}
          inputProps={{ 'aria-label': 'controlled' }}
          className="checkbox"
        />
        I would like to receive future communications and offers from Prickly Rose Clay Company, LLC.
      </div>
      <div className="button-div">
        <Button size="medium" color="info" variant="outlined" type="submit">
          Continue to Payment Details
        </Button>
      </div>
      <div className="button-div">
        <Button size="medium" color="secondary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

export default CheckoutForm;