import { API, graphqlOperation } from 'aws-amplify';
import { addOrder } from '../graphql';
import order from '../interfaces/order'
import cartItem from '../interfaces/cartItem'

export const createOrder = async (order: order, orderItems: cartItem[], paymentToken: any) => {
    const input = {...order, orderItems: JSON.stringify(orderItems), paymentToken}
    const result = await API.graphql(graphqlOperation(addOrder, input));
    return result;
}
