import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { shippingFee, taxRate } from '../handler/cartHandler'
import '../App.css';

interface Props {
  order: any,
  handlePaymentToken: any,
  handlePrevious: any
  handleCancel: any
}

function CheckoutPayment(props: Props) {
  const { order, handlePaymentToken, handlePrevious, handleCancel } = props;

  const applicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || 'sandbox-sq0idb-MpkfTN7lNUyBH2mmWGySUw';
  const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID || 'LCHEQNBW8GK5Z';

  return (
    <>
      <Divider variant="middle" className="form-divider" >Payment Details</Divider>
      <Typography gutterBottom variant="body1" component="div">
        Subtotal: ${order.subTotal.toFixed(2)}
      </Typography>
      <Typography gutterBottom variant="body1" component="div">
        Sales Tax({taxRate}%): ${order.salesTax.toFixed(2)}
      </Typography>
      <Typography gutterBottom variant="body1" component="div">
        Shipping & Handling: ${shippingFee.toFixed(2)}
      </Typography>
      <Typography gutterBottom variant="h6" component="div">
        Total: ${order.total.toFixed(2)}
      </Typography>
      <div className="payment-div">
        <PaymentForm
          applicationId={applicationId}
          locationId={locationId}
          cardTokenizeResponseReceived={ async (token: any, buyer: any) => {
            handlePaymentToken(token.token)
          }}
        >
          <CreditCard includeInputLabels />
        </PaymentForm>
      </div>
      <div className="button-div">
        <Button size="medium" color="secondary" variant="outlined" onClick={handlePrevious}>
          Previous
        </Button>
      </div>
      <div className="button-div">
        <Button size="medium" color="secondary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
}

export default CheckoutPayment;